import { call, put, takeLatest } from "redux-saga/effects";
import request, { requestAxios } from "../../utils/request";

function* fetchIncomings(payload) {
  try {
    yield put({
      type: "FETCH_WMS_INCOMINGS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/inventory/incomings?page=${payload.value.page ?? ''}&offset=${payload.value.offset ?? ''}`;

    if (payload.value.shipper)
      requestURL += `&shipper=${payload.value.shipper}`;

    if (payload.value.warehouses)
      requestURL += `&warehouses=${payload.value.warehouses}`;

    if (payload.value.products)
      requestURL += `&products=${payload.value.products}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_WMS_INCOMINGS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed fetch receivings",
        message: "Failed fetch receivings",
      },
    });
    yield put({
      type: "FETCH_WMS_INCOMINGS_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* createManifest(payload) {
  try {
    yield put({
      type: "CREATE_MANIFEST_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/inventory/incomingStockMovement`;

    const { files, ...data } = payload.value;

    const formData = new FormData();

    formData.append('idShipper', data.idShipper);
    formData.append('idWarehouse', data.idWarehouse);
    formData.append('manifestType', data.manifestType);
    formData.append('declaredQty', data.declaredQty);
    formData.append(`idAddress`, data.idAddress);

    data.manifestDetail.forEach((item, index) => {
      formData.append(`manifestDetail[${index}].product.idProduct`, item.product.idProduct);
      formData.append(`manifestDetail[${index}].available`, item.available);
      formData.append(`manifestDetail[${index}].damaged`, item.damaged);
      formData.append(`manifestDetail[${index}].missing`, item.missing);
      formData.append(`manifestDetail[${index}].extra`, item.extra);
      formData.append(`manifestDetail[${index}].expired`, item.expired);
      formData.append(`manifestDetail[${index}].supplanted`, item.supplanted);
      formData.append(`manifestDetail[${index}].externalOrder`, item.externalOrder);
      if(item.lot) {
        formData.append(`manifestDetail[${index}].lot.number`, item.lot.number);
        formData.append(`manifestDetail[${index}].lot.serial`, item.lot.serial);
        formData.append(`manifestDetail[${index}].lot.dateExp`, item.lot.dateExp);
      }
  });

    files.forEach((image, index) => {
      formData.append(`files[${index}]`, image);
    });

    formData.append('comments', data.comments);

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: formData,
    };

    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "CREATE_MANIFEST_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed fetch receivings",
        message: "Failed fetch receivings",
      },
    });
    yield put({
      type: "CREATE_MANIFEST_ERROR",
      value: error.response
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getOrderForReturn(payload) {
  try {
    yield put({
      type: "FETCH_WMS_ORDER_FOR_RETURN_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/order/forReturn/${payload.value.trackingOrReturn}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_WMS_ORDER_FOR_RETURN_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "FETCH_WMS_ORDER_FOR_RETURN_ERROR",
    });
  }
}

export function* watchWMS() {
  yield takeLatest("FETCH_WMS_RECEIVINGS_REQUEST", fetchIncomings);
  yield takeLatest("FETCH_WMS_ORDER_FOR_RETURN_REQUEST", getOrderForReturn);
  yield takeLatest("CREATE_MANIFEST_REQUEST", createManifest);
}
