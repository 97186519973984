import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Button, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select'
import Swal from "sweetalert2";
import moment from 'moment';

import { buttonIcon, buttonTypes } from "../../../../utils/buttonsUtil";

import Sidebar from "../../../../components/Navigation/Sidebar";
import Topbar from "../../../../components/Navigation/Topbar";
import Header from "../../../../components/Page/header";
import Grid from '../../../../components/Grid';
import CollapsedSection from "../../../../components/CollapsedSection";
import NumberInput from "../../../../components/Inputs/Text/Number";
import MultiFile from "../../../../components/Inputs/MultiFile";
import { DatePickerCustom } from "../../../../components/Inputs/DatePicker";
import { isNullOrUndefined } from "../../../../utils/functionsUtils";

const Receiving = ({
    reduxGetShippers,
    shippers,
    reduxGetWarehouses,
    warehouses,
    reduxGetProducts,
    products,
    reduxCreateManifest,
    successfulCreateManifest,
    errorsCreateManifest,
    createManifestResult,
    reduxResetManifestForm,
}) => {
    const {
        handleSubmit: handleAddHeader,
        control: headerControl,
        formState: { errors: headerErrors },
        watch: headerWatch,
        getValues: getHeaderValues,
        reset: resetHeader,
    } = useForm({
        defaultValues: {
            shipper: null,
            warehouse: null,
            totalDeclaredQty: 0,
            manifestType: 1,
        }
    });

    const {
        handleSubmit: handleAddDetail,
        control: detailControl,
        formState: { errors: detailErrors },
        getValues: getDetailValues,
        watch: detailsWatch,
        reset: resetDetail,
    } = useForm({
        defaultValues: {
            product: null,
            available: '',
            damaged: '',
            missing: '',
            extra: '',
            expired: '',
            supplanted: '',
            externalOrder: '',
            lotNumber: '',
            lotSerial: '',
            lotExpDate: '',
        }
    });

    const {
        handleSubmit: handleAddEvidences,
        control: evidencesControl,
        formState: { errors: evidencesError },
        setValue: setEvidencesValue,
        getValues: getEvidencesValues,
        watch: evidencesWatch,
        reset: resetEvidences,
    } = useForm({
        defaultValues: {
            files: [],
            comments: '',
        }
    });

    const history = useHistory();
    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const countryUser = userData?.idCountry;

    const [activeSectionKey, setActiveSectionKey] = useState("manifestHeader");
    const [manifestDetail, setManifestDetail] = useState([]);

    const shipperWatcher = headerWatch("shipper", null);
    const totalDeclaredQty = headerWatch("totalDeclaredQty", 0);

    const filesWatcher = evidencesWatch('files', [])
    const lotNumberWatcher = detailsWatch("lotNumber", null)

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "4.5vw", overflowY: "auto" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" })
    };

    const renderNumberInput = (inputName, placeholder, index, required = true) => (
        <Controller
            control={detailControl}
            name={inputName}
            rules={{
                required: {
                    value: required,
                    message: 'Este campo es requerido'
                },
            }}
            render={({ field }) => (
                <>
                    <div className="d-flex flex-column">
                        <NumberInput
                            {...field}
                            tabIndex={index}
                            placeholder={placeholder}
                        />
                        {
                            detailErrors[inputName] &&
                            <span className="error-message">
                                {detailErrors[inputName].message}
                            </span>
                        }
                    </div>
                </>
            )}
        />
    );

    const handleToggleSection = (sectionKey) => {
        setActiveSectionKey(prevState => (prevState === sectionKey ? null : sectionKey))
    }

    useEffect(() => {
        reduxGetShippers();
        reduxGetWarehouses({
            page: 1,
            offset: 1000,
        });
        setManifestDetail([]);
        resetHeader();
        resetDetail();
        resetEvidences();
    }, [])

    useEffect(() => {
        if (!shipperWatcher) return;

        resetDetail();
        setManifestDetail([]);

        reduxGetProducts({
            idCompany: shipperWatcher.value
        })
    }, [shipperWatcher])

    useEffect(() => {
        if (successfulCreateManifest === true) {
          Swal.fire(
            "Proceso Exitoso",
            `${createManifestResult.message}`,
            "success"
          );
          handleReset();
        }
        if (errorsCreateManifest === true) {
          Swal.fire(
            "Proceso Fallido",
            `${createManifestResult.message}`,
            "error"
          );
        }
      }, [successfulCreateManifest, errorsCreateManifest, createManifestResult])

      useEffect(() => {
        console.log(manifestDetail);
        
      }, [manifestDetail])

    const addHeader = () => {
        handleToggleSection("manifestDetail")
    }

    const addDetail = () => {
        if (!validateManifestDetail())
            return;

        handleToggleSection("manifestEvidences")
    }

    const addEvidences = () => {
        if (!validateManifestDetail())
            return;

        handleSubmitManifest();
    }

    const handleAddDetailRow = (data) => {
        const productQty = Number(data.available || 0) + Number(data.missing || 0) + Number(data.extra || 0) + Number(data.damaged || 0);

        if (productQty > totalDeclaredQty) {
            Swal.fire(
                "Información incongruente",
                `La cantidad total detallada excede la declarada en el manifiesto (${totalDeclaredQty})`,
                "error"
            );
            return;
        }

        let dataObj = {
            ...data,
            available: !isNaN(data.available) ? +data.available : 0,
            damaged: !isNaN(data.damaged) ? +data.damaged : 0,
            missing: !isNaN(data.missing) ? +data.missing : 0,
            extra: !isNaN(data.extra) ? +data.extra : 0,
            expired: !isNaN(data.expired) ? +data.expired : 0,
            supplanted: !isNaN(data.supplanted) ? +data.supplanted : 0,
            product: {
                idProduct: data.product.value,
                name: data.product.label,
            },
            lot: {
                number: data.lotNumber,
                serial: data.lotSerial,
                dateExp: data.lotExpDate && moment(data.lotExpDate).tz('America/Bogota').format('MM-DD-YYYY'),
            }
        }
        setManifestDetail([...manifestDetail, dataObj])
        resetDetail();
    }

    const handleRemoveDetailRow = (row) => {
        const newManifestDetail = manifestDetail.filter(item => item !== row);
        setManifestDetail(newManifestDetail);
    }

    const handleChangeFiles = (e) => {
        setEvidencesValue('files', Array.from(e.target.files))
    }

    const handleRemoveFile = (index) => {
        const newFiles = filesWatcher.filter((_, i) => i !== index);
        setEvidencesValue('files', newFiles)
    }

    const validateManifestDetail = () => {
        if (!manifestDetail || manifestDetail.length == 0) {
            Swal.fire(
                "Falta información",
                `No se ha identificado detalle de productos en el manifiesto`,
                "error"
            );

            if (activeSectionKey != "manifestDetail")
                handleToggleSection("manifestDetail");

            return false;
        }
        return true;
    }

    const handleSubmitManifest = () => {
        const data = {
            idShipper: getHeaderValues("shipper")?.value,
            idWarehouse: getHeaderValues("warehouse")?.value,
            declaredQty: getHeaderValues("totalDeclaredQty"),
            manifestType: getHeaderValues("manifestType"),
            manifestDetail: [...manifestDetail],
            files: getEvidencesValues("files"),
            comments: getEvidencesValues("comments"),
        }

        reduxCreateManifest(data);
    }

    const handleReset = () => {
        reduxResetManifestForm();
        resetHeader();
        resetDetail();
        resetEvidences();
        setManifestDetail([]);
        setActiveSectionKey("manifestHeader");
    }

    const actionButtons = [
        {
            onClick: () => history.push("/wms/incomings"),
            description: "Regresar a Ingresos",
            buttonType: buttonTypes.Primary,
            buttonIcon: buttonIcon.Arrow_LeftReply,
        },
    ];

    const columns = [
        {
            title: "Producto",
            render: (rowData) => {
                return <span>{rowData.product.name}</span>;
            },
        },
        {
            title: "Cantidad Disponible",
            render: (rowData) => {
                return <span>{rowData.available}</span>;
            },
        },
        {
            title: "Cantidad Averiada",
            render: (rowData) => {
                return <span>{rowData.damaged}</span>;
            },
        },
        {
            title: "Cantidad Faltante",
            render: (rowData) => {
                return <span>{rowData.missing}</span>;
            },
        },
        {
            title: "Cantidad Sobrante",
            render: (rowData) => {
                return <span>{rowData.extra}</span>;
            },
        },
        {
            title: "Cantidad Caducada",
            render: (rowData) => {
                return <span>{rowData.expired}</span>;
            },
        },
        {
            title: "Cantidad Suplantada",
            render: (rowData) => {
                return <span>{rowData.supplanted}</span>;
            },
        },
        {
            title: "Orden de Compra",
            render: (rowData) => {
                return <span>{rowData.externalOrder}</span>;
            },
        },
        {
            title: "Número de Lote",
            render: (rowData) => {
                return <span>{rowData.lot.number}</span>;
            },
        },
        {
            title: "Serie de Lote",
            render: (rowData) => {
                return <span>{rowData.lot.serial}</span>;
            },
        },
        {
            title: "Fecha de expiración",
            render: (rowData) => {
                return <span>{rowData.lot.dateExp}</span>;
            },
        },
        {
            title: "Acciones",
            render: (rowData) => {
                return (
                    <>
                        <button
                            title='Inventario por Lotes'
                            className={`btn ${buttonTypes.Danger} btn-sm btn-circle`}
                            type="button"
                            onClick={() => handleRemoveDetailRow(rowData)}
                        >
                            <i className={buttonIcon.Trash}></i>
                        </button>
                    </>
                );
            },
        },
    ]

    return (
        <>
            <div>
                <div id="wrapper">
                    <Sidebar />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar />
                            <div className="container-fluid">
                                <Header title={"Nueva Recepción"} actionButtons={actionButtons} />
                            </div>
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <h6 className="m-0 font-weight-bold text-primary">
                                        Manifiesto
                                    </h6>
                                </div>
                                <div className="card-body d-flex flex-column flex-gap-1">
                                    <CollapsedSection
                                        sectionKey="manifestHeader"
                                        title="Información principal"
                                        show={activeSectionKey === 'manifestHeader'}
                                        onToggle={() => handleToggleSection("manifestHeader")}
                                    >
                                        <form onSubmit={handleAddHeader(addHeader)}>
                                            <div className="d-flex flex-gap-1">
                                                <div className='form-group w-100'>
                                                    <label
                                                        htmlFor='shipper'
                                                        className='form-label'>
                                                        Remitente
                                                    </label>
                                                    <Controller
                                                        control={headerControl}
                                                        name="shipper"
                                                        rules={{ required: 'El remitente es requerido' }}
                                                        render={({ field }) => (
                                                            <>
                                                                <Select
                                                                    {...field}
                                                                    tabIndex={1}
                                                                    isClearable
                                                                    styles={customStyles}
                                                                    options={
                                                                        shippers && Object.keys(shippers).length > 0
                                                                            ? shippers.items
                                                                                .filter(e => e.isActive === true && e.country == countryUser && countryUser)
                                                                                .map((ele, key) => ({
                                                                                    value: ele.idCompany,
                                                                                    label: ele.description,
                                                                                }))
                                                                            : []
                                                                    }
                                                                />
                                                                {
                                                                    headerErrors.shipper &&
                                                                    <span className="error-message">
                                                                        {headerErrors.shipper.message}
                                                                    </span>
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='form-group w-100'>
                                                    <label
                                                        htmlFor='warehouse'
                                                        className='form-label'>
                                                        Bodega
                                                    </label>
                                                    <Controller
                                                        control={headerControl}
                                                        name="warehouse"
                                                        rules={{ required: 'La bodega es requerida' }}
                                                        render={({ field }) => (
                                                            <>
                                                                <Select
                                                                    {...field}
                                                                    tabIndex={2}
                                                                    isClearable
                                                                    styles={customStyles}
                                                                    options={
                                                                        warehouses && warehouses.items
                                                                            ? warehouses.items
                                                                                .filter(e => e.isActive === true && countryUser && e.warehouseHubs[0]?.hub?.idCountry == countryUser)
                                                                                .map(ele => ({
                                                                                    value: ele.idWarehouse,
                                                                                    label: ele.name || ele.warehouseCode,
                                                                                }))
                                                                            : []
                                                                    }
                                                                />
                                                                {
                                                                    headerErrors.warehouse &&
                                                                    <span className="error-message">
                                                                        {headerErrors.warehouse.message}
                                                                    </span>
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='form-group w-100'>
                                                    <label
                                                        htmlFor='totalDeclaredQty'
                                                        className='form-label'>
                                                        Cantidad Total Declarada
                                                    </label>
                                                    <Controller
                                                        control={headerControl}
                                                        name="totalDeclaredQty"
                                                        rules={{
                                                            required: 'La cantidad total declarada es requerida',
                                                            min: {
                                                                value: 1,
                                                                message: "La cantidad mínima es 1",
                                                            },
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                                <NumberInput
                                                                    value={field.value}
                                                                    onChange={field.onChange}
                                                                    tabIndex={3}
                                                                    onBlur={() => {
                                                                        if (!field.value)
                                                                            field.onChange('0');
                                                                    }}
                                                                    onFocus={() => {
                                                                        field.onChange('');
                                                                    }}
                                                                    min={1}
                                                                />
                                                                {
                                                                    headerErrors.totalDeclaredQty &&
                                                                    <span className="error-message">
                                                                        {headerErrors.totalDeclaredQty.message}
                                                                    </span>
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                <Button
                                                    variant='primary'
                                                    tabIndex={4}
                                                    type='submit'>
                                                    Confirmar
                                                </Button>
                                            </div>
                                        </form>
                                    </CollapsedSection>
                                    <CollapsedSection
                                        sectionKey="manifestDetail"
                                        title="Detalle"
                                        show={activeSectionKey === 'manifestDetail'}
                                        onToggle={() => handleToggleSection("manifestDetail")}
                                    >
                                        <form onSubmit={handleAddDetail(handleAddDetailRow)}>
                                            <div className="d-flex">
                                                <div className='form-group col-4'>
                                                    <label
                                                        htmlFor='product'
                                                        className='form-label'>
                                                        Producto
                                                    </label>
                                                    <Controller
                                                        control={detailControl}
                                                        name="product"
                                                        rules={{
                                                            required: "Debe seleccionar un producto"
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                                <Select
                                                                    {...field}
                                                                    isClearable
                                                                    styles={customStyles}
                                                                    isDisabled={!shipperWatcher}
                                                                    tabIndex={5}
                                                                    options={
                                                                        products && products.items
                                                                            ? products.items
                                                                                .filter(e => e.isActive === true)
                                                                                .map(ele => ({
                                                                                    value: ele.idProduct,
                                                                                    label: ele.name,
                                                                                }))
                                                                            : []
                                                                    }
                                                                />
                                                                {
                                                                    detailErrors.product &&
                                                                    <span className="error-message">
                                                                        {detailErrors.product.message}
                                                                    </span>
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='d-flex flex-column align-items-center w-auto'>
                                                    <label className='form-label'>Cantidades</label>
                                                    <div className="d-flex flex-gap-1">
                                                        {renderNumberInput('available', 'Disponible', 6)}
                                                        {renderNumberInput('damaged', 'Averiada', 7)}
                                                        {renderNumberInput('missing', 'Faltante', 8)}
                                                        {renderNumberInput('extra', 'Sobrante', 9)}
                                                        {renderNumberInput('expired', 'Caducados', 10, false)}
                                                        {renderNumberInput('supplanted', 'Suplantaciones', 11, false)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className='form-group col-3'>
                                                    <label
                                                        htmlFor='externalOrder'
                                                        className='form-label'>
                                                        Orden de Compra
                                                    </label>
                                                    <Controller
                                                        control={detailControl}
                                                        name="externalOrder"
                                                        render={({ field }) => (
                                                            <>
                                                                <Form.Control
                                                                    {...field}
                                                                    tabIndex={12}
                                                                    className={`form-control`}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='form-group col-2'>
                                                    <label
                                                        htmlFor='lotNumber'
                                                        className='form-label'>
                                                        Número del Lote
                                                    </label>
                                                    <Controller
                                                        control={detailControl}
                                                        name="lotNumber"
                                                        render={({ field }) => (
                                                            <>
                                                                <Form.Control
                                                                    {...field}
                                                                    tabIndex={13}
                                                                    className={`form-control`}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='form-group col-2'>
                                                    <label
                                                        htmlFor='lotSerial'
                                                        className='form-label'>
                                                        Serie del Lote
                                                    </label>
                                                    <Controller
                                                        control={detailControl}
                                                        name="lotSerial"
                                                        rules={{
                                                            required: {
                                                                value: !isNullOrUndefined(lotNumberWatcher),
                                                                message: "Serie del lote requerido"
                                                            }
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                                <Form.Control
                                                                    {...field}
                                                                    disabled={isNullOrUndefined(lotNumberWatcher)}
                                                                    tabIndex={14}
                                                                    className={`form-control`}
                                                                />
                                                                {
                                                                    detailErrors.lotSerial &&
                                                                    <span className="error-message">
                                                                        {detailErrors.lotSerial.message}
                                                                    </span>
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='form-group col-auto'>
                                                    <label
                                                        htmlFor='expDate'
                                                        className='form-label'>
                                                        Fecha de expiración del lote
                                                    </label>
                                                    <Controller
                                                        control={detailControl}
                                                        name="lotExpDate"
                                                        rules={{
                                                            required: {
                                                                value: !isNullOrUndefined(lotNumberWatcher),
                                                                message: "Fecha de expiración del lote requerido"
                                                            }
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                                <DatePickerCustom
                                                                    {...field}
                                                                    tabIndex={15}
                                                                    disabled={isNullOrUndefined(lotNumberWatcher)}
                                                                    selected={getDetailValues("lotExpDate")}
                                                                    dateFormat='MM-dd-yyyy'
                                                                />
                                                                {
                                                                    detailErrors.lotExpDate &&
                                                                    <span className="error-message">
                                                                        {detailErrors.lotExpDate.message}
                                                                    </span>
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='form-group align-content-end'>
                                                    <Button
                                                        variant='primary'
                                                        tabIndex={16}
                                                        type='submit'>
                                                        <i className={buttonIcon.Add}></i>
                                                    </Button>
                                                </div>
                                            </div>
                                            <hr className="p-0 m-0" />
                                        </form>
                                        <Grid
                                            showFilters={false}
                                            showRange={false}
                                            cols={columns}
                                            data={manifestDetail}
                                        />
                                        <div className='d-flex justify-content-center'>
                                            <Button
                                                variant='primary'
                                                tabIndex={17}
                                                onClick={addDetail}
                                                type='button'>
                                                Confirmar
                                            </Button>
                                        </div>
                                    </CollapsedSection>
                                    <CollapsedSection
                                        sectionKey="manifestEvidences"
                                        title="Evidencias y Observaciones"
                                        show={activeSectionKey === 'manifestEvidences'}
                                        onToggle={() => handleToggleSection("manifestEvidences")}
                                    >
                                        <form onSubmit={handleAddEvidences(addEvidences)}>
                                            <div className="d-flex flex-column flex-gap-1">
                                                <div className='form-group'>
                                                    <Controller
                                                        control={evidencesControl}
                                                        name="files"
                                                        render={() => (
                                                            <>
                                                                <label
                                                                    htmlFor='files'
                                                                    className='form-label'>
                                                                    Evidencias
                                                                </label>
                                                                <MultiFile
                                                                    files={filesWatcher}
                                                                    tabIndex={18}
                                                                    accept="image/*"
                                                                    onChange={handleChangeFiles}
                                                                    handleRemove={handleRemoveFile}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <Controller
                                                        control={evidencesControl}
                                                        name="comments"
                                                        rules={{
                                                            required: 'El campo comentario es requerido'
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                                <label
                                                                    htmlFor='comments'
                                                                    className='form-label'>
                                                                    Comentarios/Observaciones
                                                                </label>
                                                                <textarea
                                                                    {...field}
                                                                    className={`form-control`}
                                                                    type="textarea"
                                                                    tabIndex={19}
                                                                />
                                                                {
                                                                    evidencesError.comments &&
                                                                    <span className="error-message">
                                                                        {evidencesError.comments.message}
                                                                    </span>
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='d-flex justify-content-center'>
                                                    <Button
                                                        variant='primary'
                                                        tabIndex={20}
                                                        type='submit'>
                                                        Confirmar
                                                    </Button>
                                                </div>
                                            </div>
                                        </form>
                                    </CollapsedSection>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        products: state.productState.products,
        shippers: state.companyState.clients,
        warehouses: state.warehouseState.warehouses,
        successfulCreateManifest: state.wmsState.successfulCreateManifest,
        errorsCreateManifest: state.wmsState.errorsCreateManifest,
        createManifestResult: state.wmsState.createManifestResult,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetProducts: (payload) =>
            dispatch({
                type: "FETCH_PRODUCTS_REQUEST",
                value: payload,
            }),
        reduxGetShippers: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
                value: payload,
            }),
        reduxGetWarehouses: (payload) =>
            dispatch({
                type: "FETCH_WAREHOUSES_REQUEST",
                value: payload,
            }),
        reduxCreateManifest: (payload) =>
            dispatch({
                type: "CREATE_MANIFEST_REQUEST",
                value: payload,
            }),
        reduxResetManifestForm: () =>
            dispatch({
                type: "RESET_MANIFEST_FORM",
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Receiving);